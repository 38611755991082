import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {

  private storage: Storage | undefined;
  private _isHiddenActivitySidebar = false;
  private _hiddenActivitySidebar = new BehaviorSubject<boolean>(false);

  constructor() {
    this.storage = this.getStorage();
  }


  private getStorage(): Storage | undefined {
    try {
      if (typeof window !== 'undefined' && window.sessionStorage) {
        return window.sessionStorage;
      } else {
        return undefined;
      }
    } catch (e) {
      console.warn('sessionStorage is not available:', e);
      return undefined;
    }
  }

  getStatusActivitySidebar() {
    return this._hiddenActivitySidebar.asObservable();
  }

  toggleActivitySidebar() {
    this._isHiddenActivitySidebar = !this._isHiddenActivitySidebar;
    this._hiddenActivitySidebar.next(this._isHiddenActivitySidebar);
  }

  get<T = any>(key: string): string | null | T {
    if (this.storage?.getItem(key)) {
      try {
        return JSON.parse( this.storage.getItem(key) as string) as T
      } catch (e) {
        return this.storage.getItem( key );
      }
    } else {
      return null
    }
  }

  set<T = any>(key: string, value: T): void {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }
}
